import {
  FETCH_USER_AUTH_FAIL,
  FETCH_USER_AUTH_REQUEST,
  FETCH_USER_AUTH_SUCCESS,
} from "../Types/UserTypes";

export const getUserAuthReducer = (
  state = { loading: true, userAuth: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_USER_AUTH_REQUEST:
      return {
        loading: true,
      };
    case FETCH_USER_AUTH_SUCCESS:
      return {
        loading: false,
        userAuth: action.payload,

        error: "",
      };
    case FETCH_USER_AUTH_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};
