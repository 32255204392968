import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { getAboutAction } from "./AboutAction";
import { useNavigate } from "react-router-dom";
import { getContactAction } from "./ContactAction";

const ContactPage = () => {
  const getContact = useSelector((state) => state.getContact);
  const { contacts, loading } = getContact;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactAction());
  }, [dispatch]);
  const navigate = useNavigate();
  const handleCreate = () => {
    navigate("create");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        {/* <h2 className="text-2xl font-semibold">About Us</h2> */}
        {contacts?.length > 0 ? (
          ""
        ) : (
          <>
            {" "}
            {!loading && (
              <button
                onClick={handleCreate}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Create New
              </button>
            )}{" "}
          </>
        )}
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">#</th>
              <th className="py-2 px-4 border-b">email</th>
              <th className="py-2 px-4 border-b"> phone </th>
              <th className="py-2 px-4 border-b">Update</th>
            </tr>
          </thead>
          <tbody>
            {contacts &&
              contacts.map((about, index) => (
                <tr key={about.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{index + 1}</td>
                  <td className="py-2 px-4 border-b">{about.email}</td>
                  <td className="py-2 px-4 border-b">{about.phone1}</td>
                  <td>
                    {" "}
                    <button
                      onClick={handleCreate}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ContactPage;
