import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";

import { thunk } from "redux-thunk";
import { userLoginReducer } from "../Components/Pages/Login/Reducer/loginReducer";
import { getUserAuthReducer } from "../Components/Pages/Login/Reducer/UserReducer";
import {
  createAboutReducer,
  getAboutReducer,
  updateAboutReducer,
} from "../Components/Pages/AboutPage/AboutReducer";
import {
  createContactReducer,
  getContactReducer,
  updateContactReducer,
} from "../Components/Pages/ContactPage/ContactReducer";
import {
  createStaffReducer,
  getStaffReducer,
  showStaffReducer,
  updateStaffReducer,
} from "../Components/Pages/Staff/StaffReducer";
import {
  getHomeReducer,
  showHomeReducer,
} from "../Components/Pages/Home/HomeReducer";

const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  getUserAuth: getUserAuthReducer,

  createAbout: createAboutReducer,
  getAbout: getAboutReducer,
  updateAbout: updateAboutReducer,

  createContact: createContactReducer,
  getContact: getContactReducer,
  updateContact: updateContactReducer,

  createStaff: createStaffReducer,
  getStaff: getStaffReducer,
  updateStaff: updateStaffReducer,
  showStaff: showStaffReducer,

  getHome: getHomeReducer,
  showHome: showHomeReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
