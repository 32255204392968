import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../App.css"; // Ensure TailwindCSS is correctly imported
import { createStaffAction } from "./StaffAction";

const CreateStaffPage = () => {
  const [englishTitle, setEnglishTitle] = useState("");
  const [turkishTitle, setTurkishTitle] = useState("");
  const [englishAbout, setEnglishAbout] = useState("");
  const [turkishAbout, setTurkishAbout] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateForm = () => {
    let formErrors = {};
    if (!englishTitle) formErrors.englishTitle = "English title is required";
    if (!turkishTitle) formErrors.turkishTitle = "Turkish title is required";
    if (!englishAbout)
      formErrors.englishAbout = "English about section is required";
    if (!turkishAbout)
      formErrors.turkishAbout = "Turkish about section is required";
    if (selectedFiles.length === 0)
      formErrors.selectedFiles = "At least one image file is required";
    else if (
      selectedFiles.some(file => !["image/jpeg", "image/png", "image/gif"].includes(file.type))
    )
      formErrors.selectedFiles =
        "Invalid file type. Only JPEG, PNG, and GIF are allowed.";
    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    dispatch(
      createStaffAction(
        englishTitle,
        turkishTitle,
        englishAbout,
        turkishAbout,
        selectedFiles
      )
    );
  };

  const handleFileChange = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg">
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="mb-4 text-blue-500 hover:underline"
      >
        &larr; Back
      </button>
      <h2 className="text-lg font-semibold mb-4">Create Staff Member</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="englishTitle"
          >
            English Title
          </label>
          <input
            id="englishTitle"
            type="text"
            className={`mt-1 block w-full rounded-md border ${
              errors.englishTitle ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            value={englishTitle}
            onChange={(e) => setEnglishTitle(e.target.value)}
          />
          {errors.englishTitle && (
            <p className="text-red-500 text-xs mt-1">{errors.englishTitle}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="turkishTitle"
          >
            Turkish Title
          </label>
          <input
            id="turkishTitle"
            type="text"
            className={`mt-1 block w-full rounded-md border ${
              errors.turkishTitle ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            value={turkishTitle}
            onChange={(e) => setTurkishTitle(e.target.value)}
          />
          {errors.turkishTitle && (
            <p className="text-red-500 text-xs mt-1">{errors.turkishTitle}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="englishAbout"
          >
            English About
          </label>
          <textarea
            id="englishAbout"
            className={`mt-1 block w-full rounded-md border ${
              errors.englishAbout ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            rows="4"
            value={englishAbout}
            onChange={(e) => setEnglishAbout(e.target.value)}
          ></textarea>
          {errors.englishAbout && (
            <p className="text-red-500 text-xs mt-1">{errors.englishAbout}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="turkishAbout"
          >
            Turkish About
          </label>
          <textarea
            id="turkishAbout"
            className={`mt-1 block w-full rounded-md border ${
              errors.turkishAbout ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            rows="4"
            value={turkishAbout}
            onChange={(e) => setTurkishAbout(e.target.value)}
          ></textarea>
          {errors.turkishAbout && (
            <p className="text-red-500 text-xs mt-1">{errors.turkishAbout}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="image"
          >
            Upload Images
          </label>
          <input
            id="image"
            type="file"
            multiple
            className={`mt-1 block w-full rounded-md border ${
              errors.selectedFiles ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            onChange={handleFileChange}
          />
          {errors.selectedFiles && (
            <p className="text-red-500 text-xs mt-1">{errors.selectedFiles}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateStaffPage;
