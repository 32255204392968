import Swal from "sweetalert2";
import { api } from "../../../Api/api";
import {
  FETCH_USER_AUTH_FAIL,
  FETCH_USER_AUTH_REQUEST,
  FETCH_USER_AUTH_SUCCESS,
} from "../Types/UserTypes";
import { getToken } from "./login";

export const getUsersAuthAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: FETCH_USER_AUTH_REQUEST,
    payload: [],
  });
  try {
    const response = await api.get("user/userAuth", config);
    const { status, data } = response;

    if (status == 200) {
      dispatch({
        type: FETCH_USER_AUTH_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_USER_AUTH_FAIL,
      payload: [],
    });
  }
};

export const changePasswordAction =async (id, password) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const response = await api.put("user/password/" + id, { password }, config);
    const { status, data } = response;

    if (status == 200) {
      Swal.fire({
        icon: "success",
        timer: 1500,
        title: "Good Job",
        text: "Password Was Changed",
      });
    }
  } catch (error) {
    console.log(error);
  }
};
