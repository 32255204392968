import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { getAboutAction } from "./AboutAction";
import { useNavigate } from "react-router-dom";
import { getStaffAction } from "./StaffAction";

const StaffPage = () => {
  const getStaff = useSelector((state) => state.getStaff);
  const { staffs, loading } = getStaff;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStaffAction());
  }, [dispatch]);
  const navigate = useNavigate();
  const handleCreate = () => {
    navigate("create");
  };
  const handleUpdate = (id) => {
    navigate("update/"+id);
  };
  // console.log(staffs)
  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        {/* <h2 className="text-2xl font-semibold">About Us</h2> */}

        <button
          onClick={handleCreate}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Create New
        </button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">#</th>
              <th className="py-2 px-4 border-b">English Title</th>
              <th className="py-2 px-4 border-b">Turkish Title</th>
              <th className="py-2 px-4 border-b">English About</th>
              <th className="py-2 px-4 border-b">Turkish About</th>
              <th className="py-2 px-4 border-b">Image</th>
              <th className="py-2 px-4 border-b">Update</th>
            </tr>
          </thead>
          <tbody>
            {staffs &&
              staffs.map((staff, index) => (
                <tr key={staff.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{index + 1}</td>
                  <td className="py-2 px-4 border-b">{staff.en_title}</td>
                  <td className="py-2 px-4 border-b">{staff.tu_title}</td>
                  <td className="py-2 px-4 border-b">{staff.en_about}</td>
                  <td className="py-2 px-4 border-b">{staff.tu_about}</td>
                  <td className="py-2 px-4 border-b">
                    <img
                      src={staff.image}
                      alt="About"
                      className="w-16 h-16 object-cover rounded-md"
                    />
                  </td>
                  <td>
                    {" "}
                    <button
                      onClick={()=>handleUpdate(staff.id)}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default StaffPage;
