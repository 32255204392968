import axios from 'axios';
const baseUrl="https://panel.rozayalitim.com/app/api/v1/"

export const api=axios.create({
    // baseURL:"http://127.0.0.1:8000/api/v1/", 
    baseURL:baseUrl,

    withCredentials:true,
});


export const loginApi=axios.create({
    // baseURL:"http://127.0.0.1:8000/api/v1/", 
    baseURL:baseUrl,

    withCredentials:true,
});