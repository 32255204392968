import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createContactAction,
  getContactAction,
  updateContactAction,
} from "./ContactAction";
import "../../../App.css";

const CreateContactPage = () => {
  const [email, setEmail] = useState("");
  const [firstPhoneNumber, setFirstPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const getContact = useSelector((state) => state.getContact);
  const { contacts, loading } = getContact;
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getContactAction());
  }, []);
  useEffect(() => {
    if (contacts?.length > 0) {
      setFirstPhoneNumber(contacts[0]?.phone1);
      setEmail(contacts[0]?.email);
      setLocation(contacts[0]?.location);
    }
  }, [contacts]);
  const validateForm = () => {
    let formErrors = {};

    if (!location) formErrors.location = "Location address is required";
    if (!email) formErrors.email = "Email is required";
    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email))
      formErrors.email = "Invalid email format";
    if (!firstPhoneNumber)
      formErrors.firstPhoneNumber = "First phone number is required";

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    let id = null;
    if (contacts?.length > 0) {
      id = contacts[0]?.id;
    }

    if (id) {
      dispatch(updateContactAction(id, firstPhoneNumber, email, location));
      return;
    } else {
      dispatch(createContactAction(firstPhoneNumber, email, location));
      return;
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg">
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="mb-4 text-blue-500 hover:underline"
      >
        &larr; Back
      </button>
      <h2 className="text-lg font-semibold mb-4">Create Contact</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="email"
          >
            Email
          </label>
          <input
            id="email"
            type="email"
            className={`mt-1 block w-full rounded-md border ${
              errors.email ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">{errors.email}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="firstPhoneNumber"
          >
            Phone Number
          </label>
          <input
            id="firstPhoneNumber"
            type="text"
            className={`mt-1 block w-full rounded-md border ${
              errors.firstPhoneNumber ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            value={firstPhoneNumber}
            onChange={(e) => setFirstPhoneNumber(e.target.value)}
          />
          {errors.firstPhoneNumber && (
            <p className="text-red-500 text-xs mt-1">
              {errors.firstPhoneNumber}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="location"
          >
            Location
          </label>
          <textarea
            id="location"
            type="text"
            className={`mt-1 block w-full rounded-md border ${
              errors.location ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            {" "}
          </textarea>
          {errors.location && (
            <p className="text-red-500 text-xs mt-1">{errors.location}</p>
          )}
        </div>

        <>
          {contacts?.length > 0 ? (
            <button
              type="submit"
              className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              update
            </button>
          ) : (
            <button
              type="submit"
              className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          )}
        </>
      </form>
    </div>
  );
};

export default CreateContactPage;
