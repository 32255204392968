import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../Login/Action/login";
import {
  CREATE_CONTACT_FAIL,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  FETCH_CONTACT_FAIL,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAIL,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_SUCCESS,
} from "./ContactTypes";

export const createContactAction =
  (phone1, email, location) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    dispatch({
      type: CREATE_CONTACT_REQUEST,
      payload: [],
    });
    try {
      const response = await api.post(
        "contact/create",
        { phone1, email, location },
        config
      );
      const { status, data } = response;

      if (status == 201) {
        dispatch({
          type: CREATE_CONTACT_SUCCESS,
          payload: data,
        });
        dispatch(getContactAction());
        dispatch(getContactAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      //   console.log(error);
      dispatch({
        type: CREATE_CONTACT_FAIL,
        payload: [],
      });
    }
  };

export const getContactAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: FETCH_CONTACT_REQUEST,
    payload: [],
  });
  try {
    const response = await api.get("contact", config);
    const { status, data } = response;
    console.log("contact : ", response);
    if (status == 200) {
      dispatch({
        type: FETCH_CONTACT_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    //   console.log(error);
    dispatch({
      type: FETCH_CONTACT_FAIL,
      payload: [],
    });
  }
};

export const updateContactAction =
  (id, phone1, email, location) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    dispatch({
      type: UPDATE_CONTACT_REQUEST,
      payload: [],
    });
    try {
      const response = await api.post(
        `contact/update/${id}`,
        { phone1, email, location },
        config
      );
      const { status, data } = response;

      if (status == 200) {
        dispatch(getContactAction());
        dispatch(getContactAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: true,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_CONTACT_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      //   console.log(error);
      dispatch({
        type: UPDATE_CONTACT_FAIL,
        payload: [],
      });
    }
  };
