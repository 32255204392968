import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { logoutAction } from "../Login/Action/login";

const Sidebar = () => {
  const dispatch = useDispatch();
  return (
    <div className="h-screen w-64 bg-gray-800 text-white p-4">
      <h2 className="text-xl font-semibold mb-6">Navigation</h2>
      <nav className="flex flex-col space-y-4">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `py-2 px-4 rounded ${
              isActive ? "bg-blue-500" : "hover:bg-gray-700"
            }`
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            `py-2 px-4 rounded ${
              isActive ? "bg-blue-500" : "hover:bg-gray-700"
            }`
          }
        >
          About
        </NavLink>

        <NavLink
          to="/staff"
          className={({ isActive }) =>
            `py-2 px-4 rounded ${
              isActive ? "bg-blue-500" : "hover:bg-gray-700"
            }`
          }
        >
          Staff
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            `py-2 px-4 rounded ${
              isActive ? "bg-blue-500" : "hover:bg-gray-700"
            }`
          }
        >
          Contact
        </NavLink>
        <NavLink
          to="/changePassword"
          className={({ isActive }) =>
            `py-2 px-4 rounded ${
              isActive ? "bg-blue-500" : "hover:bg-gray-700"
            }`
          }
        >
          Change Password
        </NavLink>

        <button
        className={
          `py-2 text-left  px-4 rounded `
        }
          onClick={() => {
            
            dispatch(logoutAction());
          }}
        >
          Log Out
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;
