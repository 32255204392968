import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "../../../App.css"; // Ensure TailwindCSS is correctly imported
import { showStaffAction, updateStaffAction } from "./StaffAction";
// import { updateStaffAction, } from "./StaffAction";

const UpdateStaffPage = () => {
  const { id } = useParams();
  const [englishTitle, setEnglishTitle] = useState("");
  const [turkishTitle, setTurkishTitle] = useState("");
  const [englishAbout, setEnglishAbout] = useState("");
  const [turkishAbout, setTurkishAbout] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { staff } = useSelector((state) => state.showStaff);
  useEffect(() => {
    dispatch(showStaffAction(id));
  }, [dispatch, id]);
  useEffect(() => {
    // console.log(staff)
    setTurkishTitle(staff?.tu_title);
    setEnglishTitle(staff?.en_title);
    setTurkishAbout(staff?.tu_about);
    setEnglishAbout(staff?.en_about);
    setSelectedFile(staff?.image);
  }, [staff, id]);
  const validateForm = () => {
    let formErrors = {};
    if (!englishTitle) formErrors.englishTitle = "English title is required";
    if (!turkishTitle) formErrors.turkishTitle = "Turkish title is required";
    if (!englishAbout)
      formErrors.englishAbout = "English about section is required";
    if (!turkishAbout)
      formErrors.turkishAbout = "Turkish about section is required";

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    dispatch(
      updateStaffAction(
        id,
        englishTitle,
        turkishTitle,
        englishAbout,
        turkishAbout,
        selectedFile
      )
    );
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg">
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="mb-4 text-blue-500 hover:underline"
      >
        &larr; Back
      </button>
      <h2 className="text-lg font-semibold mb-4">Update Staff Member</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="englishTitle"
          >
            English Title
          </label>
          <input
            id="englishTitle"
            type="text"
            className={`mt-1 block w-full rounded-md border ${
              errors.englishTitle ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            value={englishTitle}
            onChange={(e) => setEnglishTitle(e.target.value)}
          />
          {errors.englishTitle && (
            <p className="text-red-500 text-xs mt-1">{errors.englishTitle}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="turkishTitle"
          >
            Turkish Title
          </label>
          <input
            id="turkishTitle"
            type="text"
            className={`mt-1 block w-full rounded-md border ${
              errors.turkishTitle ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            value={turkishTitle}
            onChange={(e) => setTurkishTitle(e.target.value)}
          />
          {errors.turkishTitle && (
            <p className="text-red-500 text-xs mt-1">{errors.turkishTitle}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="englishAbout"
          >
            English About
          </label>
          <textarea
            id="englishAbout"
            className={`mt-1 block w-full rounded-md border ${
              errors.englishAbout ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            rows="4"
            value={englishAbout}
            onChange={(e) => setEnglishAbout(e.target.value)}
          ></textarea>
          {errors.englishAbout && (
            <p className="text-red-500 text-xs mt-1">{errors.englishAbout}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="turkishAbout"
          >
            Turkish About
          </label>
          <textarea
            id="turkishAbout"
            className={`mt-1 block w-full rounded-md border ${
              errors.turkishAbout ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            rows="4"
            value={turkishAbout}
            onChange={(e) => setTurkishAbout(e.target.value)}
          ></textarea>
          {errors.turkishAbout && (
            <p className="text-red-500 text-xs mt-1">{errors.turkishAbout}</p>
          )}
        </div>
          {
            selectedFile && <img src={selectedFile} className="w-full h-20 object-cover"  alt="" />
          }
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="image"
          >
            Upload Image
          </label>
          <input
            id="image"
            type="file"
            className={`mt-1 block w-full rounded-md border ${
              errors.selectedFile ? "border-red-500" : "border-gray-300"
            } shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            onChange={handleFileChange}
          />
          {errors.selectedFile && (
            <p className="text-red-500 text-xs mt-1">{errors.selectedFile}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default UpdateStaffPage;
