import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT_SUCCESS } from "../Types/loginType";


export const userLoginReducer = (
  state = { loading: false, isLoggedIn: false, userInfo: [], error: "" },
  action
) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isLoggedIn: true,
        userInfo: action.payload,
        error: "",
      };
    case USER_LOGOUT_SUCCESS:
      return {
        isLoggedIn: false,
        userInfo: [],
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isLoggedIn: false,
        userInfo: [],
        error: action.error,
      };
    default:
      return state;
  }
};


