import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showHomeAction, updateHomeAction } from "./HomeAction";

const UpdateHomePage = () => {
  const { id } = useParams();
  const [link, setLink] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { home } = useSelector((state) => state.showHome);

  useEffect(() => {
    dispatch(showHomeAction(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (home) {
      setLink(home?.link);
      setSelectedFile(home?.image);
    }
  }, [home]);
  const validateForm = () => {
    const newErrors = {};

    if (!selectedFile) {
      newErrors.selectedFile = "An image file is required.";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    dispatch(updateHomeAction(id, link, selectedFile));
    navigate(-1); // Navigate back after submission
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg">
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="mb-4 text-blue-500 hover:underline"
      >
        &larr; Back
      </button>
      <h2 className="text-lg font-semibold mb-4">Update Home</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="link"
          >
            Link
          </label>
          <textarea
            id="link"
            className={`mt-1 block w-full rounded-md border ${
              errors.link ? "border-red-500" : "border-gray-300"
            }`}
            rows="4"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          ></textarea>
          {errors.link && <p className="text-red-500 text-sm">{errors.link}</p>}
        </div>
            {
              selectedFile && <img src={selectedFile} className="w-full h-20 object-cover" alt="" />
            }
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="image"
          >
            Upload Image
          </label>
          <input
            id="image"
            type="file"
            className={`mt-1 block w-full rounded-md border ${
              errors.selectedFile ? "border-red-500" : "border-gray-300"
            }`}
            onChange={handleFileChange}
          />
          {errors.selectedFile && (
            <p className="text-red-500 text-sm">{errors.selectedFile}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default UpdateHomePage;
