import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Components/Pages/Login/Login";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAuthAction } from "./Components/Pages/Login/Action/UserAction";
import About from "./Components/Pages/AboutPage/About";
import CreateAboutPage from "./Components/Pages/AboutPage/CreateAboutPage";
import Sidebar from "./Components/Pages/Bars/Sidebar";
import ContactPage from "./Components/Pages/ContactPage/ContactPage";
import CreateContactPage from "./Components/Pages/ContactPage/CreateContactPage";
import StaffPage from "./Components/Pages/Staff/StaffPage";
import CreateStaffPage from "./Components/Pages/Staff/CreateStaffPage";
import UpdateStaffPage from "./Components/Pages/Staff/UpdateStaffPage";
import ChangePassword from "./Components/Pages/Login/ChangePassword";
import HomePage from "./Components/Pages/Home/HomePage";
import GetHomePage from "./Components/Pages/Home/GetHomePage";
import UpdateHomePage from "./Components/Pages/Home/UpdateHomePage";

const App = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const getUserAuth = useSelector((state) => state.getUserAuth);
  const { userAuth } = getUserAuth;

  const token = sessionStorage.getItem("token");

  const dispatch = useDispatch();
  useEffect(() => {
    if (!userInfo && !token) {
      // break
      return;
    } else {
      dispatch(getUsersAuthAction());
    }
  }, [userInfo]);

  return (
    <div className=" w-full">
      <BrowserRouter>
        {userInfo && token ? (
          <div className="flex">
            <div className="">
              <Sidebar userAuth={userAuth} />
            </div>
            <div className=" w-full">
              <Routes>
              <Route path="create" element={<HomePage />} />
              <Route path="/" element={<GetHomePage />} />
              <Route path="/update/:id" element={<UpdateHomePage />} />

                <Route path="/about" element={<About />} />
                <Route path="/about/create" element={<CreateAboutPage />} />
                <Route path="/contact/create" element={<CreateContactPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/staff" element={<StaffPage />} />
                <Route path="/staff/create" element={<CreateStaffPage />} />
                <Route path="/staff/update/:id" element={<UpdateStaffPage />} />
                <Route path="/changePassword" element={<ChangePassword userAuth={userAuth} />} />
              </Routes>
            </div>
          </div>
        ) : (
          <Login />
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
