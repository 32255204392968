import React, { useEffect, useState } from "react";
import "./Layout/About.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createAboutAction,
  getAboutAction,
  updateAboutAction,
} from "./AboutAction";
import { useNavigate } from "react-router-dom";

const CreateAboutPage = () => {
  const [englishText, setEnglishText] = useState("");
  const [turkishText, setTurkishText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({}); // State to hold validation errors
  const dispatch = useDispatch();

  const getAbout = useSelector((state) => state.getAbout);
  const { abouts } = getAbout;
  useEffect(() => {
    dispatch(getAboutAction());
  }, [dispatch]);
  const createAbout = useSelector((state) => state.createAbout);
  const { loading } = createAbout;
  const validate = () => {
    const newErrors = {};
    if (!englishText) {
      newErrors.englishText = "English text is required.";
    }
    if (!turkishText) {
      newErrors.turkishText = "Turkish text is required.";
    }
    if (!selectedFile) {
      newErrors.selectedFile = "An image file is required.";
    }

    return newErrors;
  };
  useEffect(() => {
    if (abouts?.length > 0) {
      setEnglishText(abouts[0]?.en_text);
      setTurkishText(abouts[0]?.tu_text);
      setSelectedFile(abouts[0]?.image);
    }
  }, [abouts]);
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Clear any previous errors
    setErrors({});
    let id = null;
    abouts?.map((about) => {
      id = about?.id;
    });

    if (id) {
      dispatch(updateAboutAction(id, turkishText, englishText, selectedFile));
      return;
    } else {
      // Dispatch the action if validation passes
      dispatch(createAboutAction(turkishText, englishText, selectedFile));
      return;
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const navigate = useNavigate();
  return (
    <form onSubmit={handleSubmit} className="form-container">
      <button
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
        className="bg-indigo-600 p-2 rounded-md shadow-md text-white"
      >
        Back
      </button>
      <div className="mb-4">
        <label className="form-label-arabic" htmlFor="arabicInput">
          English Text
        </label>
        <textarea
          id="arabicInput"
          className="form-textarea"
          rows="4"
          value={englishText}
          onChange={(e) => setEnglishText(e.target.value)}
        ></textarea>
        {errors.englishText && (
          <p className="text-red-500 text-sm">{errors.englishText}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="form-label-kurdish" htmlFor="kurdishInput">
          Turkish Text
        </label>
        <textarea
          id="kurdishInput"
          className="form-textarea"
          rows="4"
          value={turkishText}
          onChange={(e) => setTurkishText(e.target.value)}
        ></textarea>
        {errors.turkishText && (
          <p className="text-red-500 text-sm">{errors.turkishText}</p>
        )}
      </div>
      {selectedFile && (
        <img src={selectedFile} className="h-32 w-full " alt="" />
      )}
      <div className="mb-4 mt-3">
        <label className="form-label-image" htmlFor="imageInput">
          Upload Image
        </label>
        <input
          id="imageInput"
          type="file"
          className="form-input-file"
          onChange={handleFileChange}
        />
        {errors.selectedFile && (
          <p className="text-red-500 text-sm">{errors.selectedFile}</p>
        )}
      </div>
      {abouts?.length > 0 ? (
        <button
          type="submit"
          disabled={loading}
          className={`w-full  ${
            loading
              ? "opacity-40 cursor-not-allowed"
              : "opacity-100 cursor-auto"
          } py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600`}
        >
          update
        </button>
      ) : (
        <button
          type="submit"
          disabled={loading}
          className={`w-full  ${
            loading
              ? "opacity-40 cursor-not-allowed"
              : "opacity-100 cursor-auto"
          } py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600`}
        >
          Submit
        </button>
      )}
    </form>
  );
};

export default CreateAboutPage;
