import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAboutAction } from "./AboutAction";
import { useNavigate } from "react-router-dom";

const About = () => {
  const getAbout = useSelector((state) => state.getAbout);
  const { abouts, loading } = getAbout;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAboutAction());
  }, [dispatch]);
  const navigate = useNavigate();
  const handleCreate = () => {
    navigate("create");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">About Us</h2>
        {abouts?.length > 0 || loading ? (
          ""
        ) : (
          <button
            onClick={handleCreate}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Create New
          </button>
        )}
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">#</th>
              <th className="py-2 px-4 border-b">English Text</th>
              <th className="py-2 px-4 border-b">Turkish Text</th>
              <th className="py-2 px-4 border-b">Image</th>
              <th className="py-2 px-4 border-b">Update</th>
            </tr>
          </thead>
          <tbody>
            {abouts &&
              abouts.map((about, index) => (
                <tr key={about.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{index + 1}</td>
                  <td className="py-2 px-4 border-b">{about.en_text}</td>
                  <td className="py-2 px-4 border-b">{about.tu_text}</td>
                  <td className="py-2 px-4 border-b">
                    <img
                      src={about.image}
                      alt="About"
                      className="w-16 h-16 object-cover rounded-md"
                    />
                  </td>
                  <td>
                    {" "}
                    <button
                      onClick={handleCreate}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default About;
