import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createHomeAction,
  getHomeAction,
  updateHomeAction,
} from "./HomeAction";

const HomePage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const getHome = useSelector((state) => state.getHome);
  const { homes, loading } = getHome;

  useEffect(() => {
    dispatch(getHomeAction());
  }, [dispatch]);

  useEffect(() => {
    if (homes?.length > 0) {
      setEmail(homes[0]?.email);
      setPhone(homes[0]?.phone);
      return;
    }
  }, [homes]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let id = null;
    if (homes?.length > 0) {
      id = homes[0]?.id;
    }
    if (id) {
      dispatch(updateHomeAction(id, email, phone));
      console.log("jf");
      dispatch(getHomeAction());
      dispatch(getHomeAction());
      return;
    } else {
      dispatch(createHomeAction(email, phone));

      dispatch(getHomeAction());
      dispatch(getHomeAction());
      return;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="email"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              className={`mt-1 block w-full rounded-md border 
            `}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="firstPhoneNumber"
            >
              Phone Number
            </label>
            <input
              id="firstPhoneNumber"
              type="text"
              className={`mt-1 block w-full rounded-md border `}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          {loading ? (
            ""
          ) : (
            <>
              {" "}
              {homes?.length > 0 && (
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Update
                </button>
              )}
              {homes?.length <= 0 && (
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Submit
                </button>
              )}{" "}
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default HomePage;
