import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHomeAction } from "./HomeAction";
const GetHomePage = () => {
  const getHome = useSelector((state) => state.getHome);
  const { homes, loading } = getHome;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeAction());
  }, [dispatch]);
  const navigate = useNavigate();
  const handleCreate = () => {
    navigate("create");
  };
  const handleUpdate = (id) => {
    navigate("update/" + id);
  };
  // console.log(homes)
  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        {/* <h2 className="text-2xl font-semibold">About Us</h2> */}
      {
        homes?.length>0?"":<button
        onClick={handleCreate}
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Create New
      </button>
      }
        
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">#</th>
              <th className="py-2 px-4 border-b">Email</th>
       
              <th className="py-2 px-4 border-b">Phone</th>
              <th className="py-2 px-4 border-b">Update</th>
            </tr>
          </thead>
          <tbody>
            {homes &&
              homes.map((home, index) => (
                <tr key={home.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{index + 1}</td>
                  <td className="py-2 px-4 border-b">{home.email}</td>
              
                  <td className="py-2 px-4 border-b">
                    {home.phone}
                  </td>
                  <td>
                    {" "}
                    <button
                      onClick={handleCreate}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GetHomePage;
