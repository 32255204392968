import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../Login/Action/login";
import {
  CREATE_HOME_FAIL,
  CREATE_HOME_REQUEST,
  CREATE_HOME_SUCCESS,
  FETCH_HOME_FAIL,
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  SHOW_HOME_FAIL,
  SHOW_HOME_REQUEST,
  SHOW_HOME_SUCCESS,
  UPDATE_HOME_FAIL,
  UPDATE_HOME_REQUEST,
  UPDATE_HOME_SUCCESS,
} from "./HomeType";

export const getHomeAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: FETCH_HOME_REQUEST,
    payload: [],
  });
  try {
    const response = await api.get("home", config);
    const { status, data } = response;
    console.log(response);
    if (status == 200) {
      dispatch({
        type: FETCH_HOME_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    //   console.log(error);
    dispatch({
      type: FETCH_HOME_FAIL,
      payload: [],
    });
  }
};

export const createHomeAction = (email, phone) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: CREATE_HOME_REQUEST,
    payload: [],
  });
  try {
    const response = await api.post("home/create", { email, phone }, config);
    const { status, data } = response;

    if (status == 200) {
      dispatch({
        type: CREATE_HOME_SUCCESS,
        payload: data,
      });

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  } catch (error) {
    //   console.log(error);
    dispatch({
      type: CREATE_HOME_FAIL,
      payload: [],
    });
  }
};

export const showHomeAction = (id) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: SHOW_HOME_REQUEST,
    payload: [],
  });
  try {
    const response = await api.get("home/show/" + id, config);
    const { status, data } = response;
    //   console.log(response);
    if (status == 200) {
      dispatch({
        type: SHOW_HOME_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    //   console.log(error);
    dispatch({
      type: SHOW_HOME_FAIL,
      payload: [],
    });
  }
};

export const updateHomeAction = (id, email, phone) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: UPDATE_HOME_REQUEST,
    payload: [],
  });
  try {
    const response = await api.post(
      "home/update/" + id,
      { email, phone },
      config
    );
    const { status, data } = response;

    if (status == 200) {
      dispatch({
        type: UPDATE_HOME_SUCCESS,
        payload: data,
      });

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  } catch (error) {
    //   console.log(error);
    dispatch({
      type: UPDATE_HOME_FAIL,
      payload: [],
    });
  }
};
